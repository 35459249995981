#home {
  &.menu {
    display: flex;
    flex-wrap: wrap;
    background-color: #22223b;
    height: 100vh;
    align-content: flex-start;;

    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 45vw;
      height: 45vw;
      margin: 2.5vw;
      padding: 1.5rem;
      text-align: center;
      border-radius: 2rem;
      font-size: 1.3rem;
      background-color: white;
      box-shadow: rgb(255 255 255 / 24%) 0px 3px 8px;
      >:last-child {
        margin-top: 0.5rem;
      }
    }
  }
}
