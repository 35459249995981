#delivery-interface {
  min-width: 100vw;

  #navigation {
    background-color: #22223b;
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  #order-view {
    padding-bottom: 80px;

    > .header {
      background-color: #22223b;
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: white;
      font-size: 1.5rem;
      font-weight: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > .content {
      .restaurant-title {
        padding: 10px 20px;
      }
      .accordion-content {
        padding: 0 10px;
      }

      .customer-information {
        padding: 10px 20px;

        .svg-icon {
          max-width: 20px;
          margin-right: 5px;
        }
      }
      .delivery-information {
        padding: 10px 20px;

        .svg-icon {
          max-width: 20px;
          margin-right: 5px;
        }

        .time {
          font-size: 0.8rem;
          padding: 0 5px;
          background-color: #67bc75;
          color: white;
          border-radius: 6px;

          &.is-late {
            background-color: #bc6767;
          }
          &.disabled {
            background-color: #bdbdbd;
          }
        }
      }

      .payment-information {
        padding: 10px 20px;
      }

      .confirm-delivery {
        padding: 10px 20px;
      }

      .delivery-warning {
        padding: 10px 20px;
      }
    }
  }

  #orders-overview,
  #completed-orders-overview {
    > .header {
      background-color: #22223b;
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: white;
      font-size: 1.5rem;
      font-weight: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .order-row {
      padding: 1rem 1rem;
      position: relative;

      &.canceled {
        background-color: #eacdcd !important;
      }

      .number {
        font-size: 2rem;
        align-self: center;
        flex-basis: 4rem;
        text-align: center;
        gap: 0.6rem;
      }
      .customer {
        font-weight: 700;
        font-size: 0.8rem;
      }
      .restaurants-status {
        margin-left: auto;
      }
      .additional-info {
        position: absolute;
        top: 0;
        right: 0;
      }
      .time-instruction {
        font-size: 1.2rem;
        padding: 3px 5px;
        background-color: #67bc75;
        color: white;
        font-weight: bold;
        border-radius: 0px 0px 0px 6px;

        &.is-late {
          background-color: #bc6767;
        }
      }
      .payment-info {
        font-size: 1.2rem;
        padding: 3px 5px;
        background-color: #67bc75;
        color: white;
        font-weight: bold;
        border-radius: 0px 0px 0px 6px;
      }
      .time {
        font-size: 1rem;
        font-weight: 800;
        margin-left: 0.3rem;
      }
      .cancel-label {
        align-items: flex-end;
        font-size: 1.3rem;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 0.5;
      }
    }

    .pagination {
    }
  }

  #completed-order {
    .customer-information {
      margin: 0 1rem;
      margin-top: 1rem;

      .svg-icon {
        width: 20px;
        margin-right: 5px;
      }
    }

    .payment-information {
      margin: 0 1rem;
    }

    .restaurant-title {
      margin: 0 1rem;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .accordion-content {
      margin: 0 1rem;
      font-size: 1.1rem;
    }
  }
}

.deliver-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  font-size: 3rem;
  text-align: center;
  line-height: 3rem;
  font-weight: 500;
  opacity: 0.3;
}
