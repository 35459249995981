#profile {
  .body {
    padding: 1rem;
  }

  .navigation {
    background-color: #22223b;
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}