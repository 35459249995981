.column {
  display: flex;
  flex-direction: column;

  &.children-margin {
    &.tiny {
      > :nth-child(n) {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
    &.small {
      > :nth-child(n) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    &.medium {
      > :nth-child(n) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    &.large {
      > :nth-child(n) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    > :first-child {
      margin-top: 0 !important;
    }
    > :last-child {
      margin-bottom: 0 !important;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0 !important;
  }

  &.children-margin {
    &.tiny {
      > :nth-child(n) {
        margin: 0 0.25rem;
      }
    }
    &.small {
      > :nth-child(n) {
        margin: 0 1rem;
      }
    }
    &.medium {
      > :nth-child(n) {
        margin: 0 2rem;
      }
    }
    &.large {
      > :nth-child(n) {
        margin: 0 3rem;
      }
    }

    > :first-child {
      margin-left: 0 !important;
    }
    > :last-child {
      margin-right: 0 !important;
    }
  }
}

.separator {
  border-top: 1px solid #e6e6e6;
  margin: 1rem 0;

  &.no-margin {
    margin: 0;
  }

  &.vertical {
    height: auto;
    margin: 0 2rem;
    border-top: none;
    border-left: 1px solid #e6e6e6;
  }
}

.no-data {
  margin: 1.5rem 0;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.5rem;
  opacity: 0.3;
}

.order-status {
  border-radius: 12px;
  border: 3px solid white;
  font-weight: 500;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  width: fit-content;
  white-space: nowrap;
  &.large {
    padding: 0.3rem 1rem;
    font-size: 1.2rem;
    border-radius: 18px;
  }
}

.payment-status {
  border-radius: 12px;
  border: 3px solid white;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  &.large {
    padding: 0.3rem 1rem;
    font-size: 1.2rem;
    border-radius: 18px;
  }
  &.small {
    padding: 0.1rem 0.4rem;
    font-size: 0.8rem;
  }
}

.restaurant-products {
  $summary-padding: 0.5rem 1rem;
  .restaurant {
    .title {
      padding: $summary-padding;
      font-size: 1rem;
      .name {
        font-size: 1.5rem;
        font-weight: 700;
        margin-left: 0.3rem;
      }
    }

    .product {
      font-size: 1.4rem;
      transition: background-color 0.5s;
      padding: $summary-padding;

      &.canceled {
        background-color: #ffdede;
        .name {
          text-decoration: line-through;
        }
      }

      .count {
        background-color: #22223b;
        width: 30px;
        height: 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        font-weight: 700;
      }

      .name {
        font-size: 1.2rem;
      }

      .price-wrapper {
        position: relative;
        margin-left: auto !important;

        > .item-price {
          opacity: 1;
          transition: opacity 0.1s ease-in-out;
        }
      }
      .item-price {
        position: absolute;
        right: 0px;
      }

      .item-additionnal-information {
        opacity: 0.5;
        padding-left: 0.5rem;
        font-size: 1rem;
        gap: 0.3rem;
        border-left: solid 1px #909090;
        padding: 0.4rem;
        margin-left: 1rem;

        .item-options {
          font-weight: 700;
        }

        .option-choices {
          font-weight: 400;
        }

        .comments {
          font-size: 0.9rem;
        }
      }
    }
  }

  .cart-prices {
    padding: $summary-padding;
    padding-bottom: 0.5rem;

    .price-row {
      margin-bottom: 0.5rem;
      .title {
        font-size: 1.2rem;
      }
      .price {
        font-size: 1.2rem;
      }
    }
  }
}

.ping {
  background-color: #3d3d7a;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: relative;
  flex-shrink: 0;
  .animated {
    position: absolute;
    background-color: #3d3d7a;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    animation: grow-and-fade 1s infinite;
  }
  &.slow {
    .animated {
      animation: grow-and-fade 1.5s infinite;
    }
  }
  &.fast {
    .animated {
      animation: grow-and-fade 0.5s infinite;
    }
  }
  &.fastest {
    .animated {
      animation: grow-and-fade 0.3s infinite;
    }
  }
}

@keyframes grow-and-fade {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
