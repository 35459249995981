
#messenger-warning {
  min-height: 100vh;
  min-width: 100vw;
  padding-bottom: 20vh;
  background-color: #22223b;

  padding: 2rem;
  color: white;

  .verification-code {
    display: flex;
    justify-content: center;
    font-size: 4rem;
  }
}
